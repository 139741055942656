<template>
  <main class="is-scrollable">
    <router-view v-if="!error" @error="error = true" @loaded="loaded" />
    <error v-else />
  </main>
</template>

<script>
export default {
  name: "Print",
  components: {
    error: () => import("@views/error")
  },
  data() {
    return {
      error: false,
      prompted: false
    };
  },
  mounted() {
    this.$emit("mounted");
  },
  methods: {
    loaded() {
      setTimeout(() => {
        if (!this.prompted) window.print();
        this.prompted = true;
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
main {
  height: 100vh;
  max-width: 768px;
  padding: 3rem;
  margin: auto;
}
</style>
